import React, {useEffect} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import CustomLink from "../../components/link";

const jQuery = require("jquery");

const NosotrosPage = (props) => {


    useEffect(() => {
        jQuery('.preloader').delay(200).fadeOut(500);
        const id = window.location.hash;
        if (id) {
            jQuery("html, body").animate({scrollTop: jQuery(id).offset().top - 80}, 500);
        }

        jQuery(".team-member-details").on('click', function (event) {
            var $this = jQuery(this);
            var item = $this.data('item');
            jQuery(".diretivo-perfil").addClass('d-none');
            jQuery('.diretivo-perfil.' + item).removeClass("d-none");
            jQuery("html, body").animate({scrollTop: jQuery('.diretivo-perfil.' + item).offset().top - 200}, 1000);
        });

        jQuery(".cerrar-info i").on('click', function (event) {
            jQuery(".diretivo-perfil").addClass('d-none');
        });
    });

    return (
        <Layout location={props.location}>
            <SEO title="Nosotros"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">
                                    Un equipo de expertos con una sólida experiencia y resultados probados</h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    ¿Quiénes somos?</h2>
                            </div>

                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="acerca-de">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-5">
                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    Latam Capital Advisors - LCA
                                </h6>
                                <ul className="p-0 list-style-02 margin-2-rem-top margin-3-rem-bottom">
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">Somos una compañía de servicios financieros focalizada en América Latina y con alcance global.</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">Nuestros profesionales poseen una perspectiva única fundamentada en décadas de experiencia en el sector financiero.</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">Contamos con un conocimiento profundo y especializado en finanzas, compromiso con el cliente a nivel directivo y soluciones innovadoras.</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">Trabajamos de cerca con nuestros clientes ofreciendo servicios de primer nivel y entregando consistentemente resultados superiores a los esperados. </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 sm-margin-30px-bottom">
                                <img className="" src={"/images/lca/nosotros.jpg"} alt={''}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="trayectoria" className="bg-light-gray wow animate__fadeIn">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 tab-style-01 wow animate__fadeIn" data-wow-delay="0.4s">
                                <div className="tab-content">
                                    {/* start tab item */}
                                    <div id="planning-tab" className="tab-pane fade in active show">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-right sm-margin-40px-bottom">
                                                <img src={"/images/lca/nosotros2.jpg"} className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">
                                                <span className="alt-font d-block text-extra-medium margin-15px-bottom">Una destacada historia de resultados</span>
                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">Nuestra
                                                    trayectoria</h5>
                                                <p>Iniciamos operaciones en 2009, como una subsidiaria de MBIA Inc
                                                    (NYSE: MBI), y operamos de manera independiente a partir de
                                                    2012. Somos un grupo de profesionales con una amplia experiencia
                                                    en el sector bancario, de infraestructura y financiamiento de
                                                    proyectos.</p>
                                                <p> Desde su creación, Latam Capital Advisors, ha participado
                                                    directamente en la asesoría y estructuración de más
                                                    de 25 financiamientos, fusiones y adquisiciones,
                                                    por un monto total de más de <b>US$14.5 billones</b>, en adición
                                                    a proyectos en curso por más de US$3 billones. </p>
                                                <p>A partir de 2020, administramos el fondo de capital privado Latam
                                                    Capital Fondo I, dedicado a promover inversiones en los sectores en
                                                    los que nos especializamos en la región.
                                                </p>
                                                <CustomLink to="nosotros#transacciones"
                                                            className="btn btn-medium btn-dark-gray margin-15px-top">Conoce
                                                    nuestra trayectoria de resultados</CustomLink>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pb-0' id="equipo">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-7 col-sm-8 text-center margin-3-rem-bottom wow animate__fadeIn">

                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">Equipo
                                    directivo</h5>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-5 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure className={'team-member-details'} data-item="1">
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/lca/equipo/eugenio.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Eugenio Mendoza</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">Socio de LCA</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure className={'team-member-details'} data-item="2">
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/lca/equipo/kenett.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Kenneth Kryzda</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">Socio de LCA</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure className={'team-member-details'} data-item="3">
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/lca/equipo/gonzalo.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Gonzalo Obregón</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">Socio de LCA</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure className={'team-member-details'} data-item="4">
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/lca/equipo/francisco.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Francisco Cuauhtémoc Morales</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">Socio de LCA</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure className={'team-member-details'} data-item="5">
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/lca/equipo/federico.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">
                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Federico Patiño</span>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                {/*detalles*/}
                <section className="d-none diretivo-perfil 1 wow animate__fadeIn pb-1">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Eugenio Mendoza</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        Socio de LCA
                                    </em>
                                </div>
                                <p>Cuenta con más de 30 años de experiencia en financiamientos para proyectos de
                                    infraestructura subsoberanos,
                                    clientes
                                    corporativos y financiamientos
                                    estructurados. Comenzó su carrera con Citibank participando
                                    en financiamientos de exportación.</p>
                                <p>Por 18 años, trabajó para Merrill Lynch como banquero de
                                    inversión en Puerto Rico, Nueva York y Londres. Desarrolló y
                                    estuvo a cargo de la unidad global de financiamiento de
                                    infraestructura basado en Nueva York y Londres.
                                </p>
                                <p>Previo a Latam Capital Advisors, desarrolló el negocio de garantías financieras en
                                    Latinoamérica y Canadá para MBIA, incluyendo el primer
                                    financiamiento a 30 años en el mercado de capitales
                                    mexicano, varios financiamientos de activos de
                                    infraestructura en Chile y la utilización de productos
                                    estructurados para mitigar riesgos de moneda y tasa de
                                    interés.
                                </p>
                                <p>Ha participado en la compra/venta de concesiones
                                    carreteras en Canadá, China, Argentina, Brasil y México, la
                                    compra y/o financiamiento de aeropuertos en Argentina,
                                    Australia y Puerto Rico, la compra/venta de activos de
                                    energía en diversos países de Europa Occidental y el manejo
                                    de activos de infraestructura en varios países de
                                    Latinoamérica.</p>
                                <p>Boston College (MBA)
                                </p>
                                <p>Universidad Iberoamericana (Licenciatura en Contaduría)</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 2 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Kenneth Kryzda</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        Socio de LCA
                                    </em>
                                </div>
                                <p>Cuenta con más de 35 años de experiencia en la banca global,
                                    especialmente con un amplio conocimiento en
                                    estructuración de proyectos de infraestructura y fusiones y
                                    adquisiciones en América Latina.
                                </p><p>Previo a LCA, fue director ejecutivo y director general de la
                                división de Banca Global (Global Banking) en HSBC donde
                                construyó un exitoso equipo global orientado a clientes y
                                convirtió dicha división en la línea de negocios más rentable
                                de HSBC durante 3 años consecutivos. Desarrolló el equipo
                                de Financiamiento de Proyectos con enfoque en
                                infraestructura, llevándolo a una posición de liderazgo en
                                México.</p><p>Director ejecutivo en Bank of America en CDMX, donde
                                actuó como co-director general interino de la división de
                                banca de inversión. Dirigió los grupos de Medios y
                                Telecomunicaciones, Marcas de Consumo y Recursos
                                Naturales.
                            </p><p>Previo a Bank of America, fue director ejecutivo en
                                JPMorgan Chase donde dirigió los grupos de cobertura de
                                banca de inversión para Bienes de Consumo, Recursos
                                Naturales, Transportación y Conglomerados Mexicanos.</p><p>Inició su carrera con Chase
                                Manhattan Bank en NY.
                            </p><p>Southern Methodist University (Licenciatura en
                                Finanzas/Licenciatura en Economía).
                            </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 3 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Gonzalo Obregón</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        Socio de LCA
                                    </em>
                                </div>
                                <p>Cuenta con más de 20 años de experiencia en finanzas, incluyendo la
                                    suscripción de financiamientos para proyectos de
                                    infraestructura en Latinoamérica. Actualmente basado en Nueva York.</p><p>Previo a
                                LCA, estuvo más de 9 años
                                con el grupo de
                                desarrollo de nuevos de negocios de MBIA donde estructuró y
                                negoció mas de 10 operaciones financieras para clientes en
                                México y Chile por cerca de $4.5 mil mdd y además estuvo
                                encargado de originar operaciones y de manejar relaciones
                                con clientes de la región.</p><p>Previo a MBIA, trabajó 4 años como consultor con la
                                empresa
                                chilena especializada en asesoría financiera en la región,
                                Cohen & Asociados, donde desarrolló equipos de trabajo y
                                lideró proyectos de consultoría financiera para compañías
                                industriales y financieras.</p><p>Comenzó su carrera en Banco de Chile, el banco
                                comercial
                                más grande del país, desarrollando modelos de simulación
                                para la cartera de créditos para la división de consumo del
                                banco.</p><p>UC Berkeley (Maestría en Ingeniería Financiera).
                            </p><p>Universidad de Chile (Ingeniería Civil Industrial).</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 4 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Francisco Cuauhtémoc Morales</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        Socio de LCA
                                    </em>
                                </div>
                                <p>Cuenta con más de 15 años de experiencia en análisis financiero y económico
                                    y en desarrollo de
                                    modelos financieros de alta
                                    sofisticación técnica.</p><p>Previo a LCA, participó 5 años en el grupo de
                                desarrollo de nuevos negocios de
                                MBIA al que
                                se
                                incorporó
                                en 2007 y donde estructuró y negoció financiamientos para proyectos de infraestructura y
                                entes sub-soberanos por más de 800 mdd.</p><p>Ha realizado valuaciones para diferentes
                                activos de
                                infraestructura y corporativos con operaciones en América
                                Latina.
                            </p><p>Inicio su carrera como Economista en el área de Investigación Económica de Banco de
                                México, desempeñando proyectos de
                                investigación sobre temas diversos y desarrollando modelos
                                econométricos y estadísticos.</p><p>Chartered Financial Analyst (CFA).
                            </p><p>Yale University - School of Management (MBA).</p><p>Instituto Tecnológico de Estudios
                                Superiores de Monterrey
                                (Licenciatura en Economía).
                            </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 5 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Federico Patiño</h3>
                                <div className="persona-cargo ">

                                </div>
                                <p>Cuenta con más de 30 años de experiencia en el sector financiero.</p>
                                <p>Comenzando en
                                    2015 a
                                    2018 fue Director General de Grupo Aeroportuario de la
                                    Ciudad de México S.A de C.V. (GACM) y anteriormente se
                                    desempeñó como director financiero de GACM desde 2014.
                                    Durante este período, fue responsable del financiamiento
                                    del proyecto del nuevo aeropuerto y de la “Terminal 2" del
                                    aeropuerto internacional de la Ciudad de México.
                                </p>
                                <p>Previo a su rol en GAMC, Federico estuvo a cargo de la fundación y dirección general
                                    del Fondo Nacional
                                    de
                                    Infraestructura (FONADIN) en el Banco Nacional de Obras y
                                    Servicios Públicos, S.N.C. (BANOBRAS).</p>
                                <p>De 1980 a 2008, trabajó en Nacional Financiera, S. N. C.,
                                    Institución de Banca de Desarrollo (NAFIN), donde ocupó
                                    varios cargos incluyendo, entre otros, Director General de
                                    Crédito, Director General de Tesorería, Director General de
                                    Desarrollo y Director General de Banca de Inversión.</p><p>Fue responsable de la
                                creación de la Corporación Mexicana
                                de Inversiones de Capital (CMIC), un fondo de fondos de
                                capital privado creado en 2006 por la banca de desarrollo
                                mexicana. CMIC invierte en fondos de capital privado que
                                financian empresas mexicanas.</p>

                            </div>
                        </div>
                    </div>
                </section>
                {/*end detalles*/}

                <section className='pb-0' id="reconocimientos">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-12  text-center margin-3-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">
                                <h5 className="mb-1 alt-font font-weight-500 text-extra-dark-gray">Reconocimientos</h5>
                                <span
                                    className="d-inline-block alt-font text-extra-dark-gray text-large text-uppercase font-weight-500 letter-spacing-1px margin-20px-bottom margin-10px-top">+20 premios y reconocimientos internacionales en nuestra trayectoria</span>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p1_y_p3.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best <br></br>Financing Innovation</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">IFREM MXN $4,100m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p2.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America <br></br>Domestic Currency Bond</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">IFREM MXN $4,100m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p1_y_p3.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best <br></br>Structured Financing Deal</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">IFREM MXN $4,100m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p4.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Latin America Project <br></br>Bond Deal of the Year</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA Infraestructura Social MXN $8,800m</span>

                                    </figcaption>
                                </figure>
                            </div>


                        </div>
                    </div>

                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p5.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America<br></br> Domestic Currency Bond</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA Infraestructura Social MXN $8,800m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p6.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best <br></br>Financing Innovation</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA Infraestructura Social
MXN $8,800m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/awards2014.jpg"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">IJ Global Americas <br/> Awards 2014</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA: LIPSA | ICASAL MXN $3,800m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p7.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Airport Finance<br></br> Deal of the Year - Americas</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p8.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America <br></br>Airport
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p9.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America<br></br> Deal of the Year</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p10.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Airport Finance<br></br> Innovative Deal of the Year </span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p11.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Latin America<br></br> Corporate Bond </span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $2,000m Green Bond</span>

                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p12_y_p13.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Structured Financing<br></br> of the Year
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $2,000m Green Bond
</span>
                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p14_16.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best Loan</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p12_y_p13.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Syndicated Loan <br></br>of the Year

</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p14_16.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best Infrastructure<br></br> Financing Mexico
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p14_16.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best<br></br> Airport Financing
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p17.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Green Bond Pioneer:<br></br> Largest Corporate
Green Bond


</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $2,000m Green Bond


</span>
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p18.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best Loan



</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p19_p20.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best <br></br>Airport Financing
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $4,000m Green Bond

</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p19_p20.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best Local<br></br>
                                        Currency Financing

                                        </span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $1,600m Fibra E
</span>

                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='pb-0' id="transacciones">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-12  text-center margin-3-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">
                                <h5 className="mb-1 alt-font font-weight-500 text-extra-dark-gray">Trayectoria de
                                    transacciones</h5>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,750 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/1.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera para la estructuración de financiamiento bancario
                                            preferente</p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2020</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/2.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados bursátiles
                                            preferentes a
                                            largo plazo clave de pizarra CAMSCB 19U
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2019</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $30,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/3.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera para la emisión de CBFEs (FIBRA E) más grande colocada en
                                            México
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/4.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera para la estructuración, negociación y contratación de
                                            crédito en UDIs para refinanciamiento bancario
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $2,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/5.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera para la licitación y contratación de financiamiento para
                                            contrato de Mantenimiento, Rehabilitación y Operación (MRO)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP 1,581 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/28.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p> Asesoría financiera para la licitación y contratación de financiamiento para contrato de mantenimiento, rehabilitación y operación bajo esquema APP
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $4,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/6.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración de emisión de bonos a 10 y 30 años 144 A
                                            / Reg S en EEUU, Europa y Asia
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2017</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $2,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/7.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración de emisión de bonos a 10 y 30 años 144 A
                                            / Reg S en EEUU, Europa y Asia
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2016</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $3,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/8.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración, negociación y contratación de línea de
                                            crédito revolvente con sindicato bancario
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2015</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $5,225 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/9.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y negociación de reestructura de crédito bancario
                                            preferente
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2015</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,470 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/10.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados bursátiles
                                            subordinados a largo plazo
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2015</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $1,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/11.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera para la estructuración y contratación de línea de crédito
                                            simple (club deal)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP ~$3,014 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/12.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera para la venta parcial (M&A) de 4 activos carreteros en
                                            México y formación del vehículo OVT
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,600 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/13.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados bursátiles de largo
                                            plazo (PAMMSCB 14U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,800 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/14.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados preferentes (LPSLCB
                                            14U y 14-2U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,750 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/15.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados subordinados
                                            (LIPSBCB 12U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $2,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/16.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoor financiero y agente estructurador de certificados bursátiles
                                            subordinados de largo plazo (AMCCB 13U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidencial</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/21.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración de crédito a corto plazo
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $4,200 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/22.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados bursátiles
                                            preferentes de largo plazo
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $825 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/23.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados bursátiles
                                            subordinados de largo plazo
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/17.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera en la contratación de crédito bancario estructurado para infraestructura social
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2012</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,700 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/18.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesor financiero y agente estructurador de certificados subordinados (PSBCB
                                            12U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2012</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $7,100 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/19.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración de certificados preferentes a largo
                                            plazo durante construcción
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2011</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidencial</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/20.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración de financiamiento subordinado a largo
                                            plazo
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2011</b></p>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidencial</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/24.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración de proyecto APP y fuente de pago para
                                            licitación de Edificio Administrativo
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2011</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP ~$4,300 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/25.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera y estructuración de certificados bursátiles a largo plazo
                                            con garantías financieras
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2010</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Consultoría</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/26.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Consultoría al Gobierno de Nuevo León y REA sobre la valuación del Periférico
                                            de Monterrey
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2009</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidencial</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/27.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Asesoría financiera para la estructuración de financiamiento subordinado de
                                            la autopista México-Toluca
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2009</b></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default NosotrosPage
